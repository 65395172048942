.custom-select{
    padding: 10px 20px;
    border: 1px solid #555;
    background: transparent;
    color: #d6d0d0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    border-radius: 4px;
    cursor: pointer;

    option {
        background: #262632;
        height: 30px;
        line-height: 50px;
        cursor: pointer;
    }
}