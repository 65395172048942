.profile {

    .profile-header {
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0;

        h4 {
            font-size: 20px;
        }

        .image_profile {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            border: 1px solid #5e5e5e;
            overflow: hidden;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: fill;
            object-position: center;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .mt-4 {
            margin-top: 20px;
        }

        .sections {
            border: 1px solid #5e5e5e;
            border-radius: 10px;
            padding: 15px;

            .sub-section {
                display: flex;
                align-items: center;
                column-gap: 15px;
                margin-bottom: 10px;
            }

            .sub-heading {
                font-size: 16px;
                font-weight: 200;
            }

            .sub-text {
                font-size: 14px;
                font-weight: 200;
            }
        }

        h4 {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: 500;
        }
    }

}